<template>
  <v-container fluid>
    <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
      <v-row>
        <v-col cols="12">
          <v-row class="px-3">
            <v-col cols="12" class="pb-0 mt-7">
              <p class="title font-weight-bold mb-0">
                Clasificación de mi evento
              </p>
            </v-col>
            <v-col cols="12">
              <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                <v-row class="px-2">
                  <v-col cols="12" sm="6" md="4" class="pb-0">
                    <v-select
                      v-model="currentEvent.type"
                      :items="eventType"
                      label="Tipo de Evento"
                      filled
                      rounded
                      required
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="pb-0">
                    <v-combobox
                      hide-selected
                      label="Etiquetas del evento"
                      multiple
                      persistent-hint
                      hide-details
                      small-chips
                      filled
                      :items="previousTags"
                      v-model="currentEvent.tags"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Presiona
                              <kbd>enter</kbd> para crear una nueva etiqueta.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="pb-0">
                    <v-select
                      v-model="currentEvent.eventModality"
                      :items="modalityCategories"
                      label="Modalidad"
                      filled
                      rounded
                      required
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      <!--<template slot="selection" slot-scope="data">
                            <span>{{ data.item.item }}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <span>{{ data.item.item }}</span>
                          </template>-->
                    </v-select>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="px-3">
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0 mt-3">
                  <p class="title font-weight-bold mb-0">
                    Configuración del sitio
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                    <v-row class="px-2">
                      <v-col cols="12" class="pb-0">
                        <v-select
                          v-model="currentEvent.amenities"
                          :items="eventAmenities"
                          label="Amenidades"
                          filled
                          rounded
                          required
                          chips
                          multiple
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        >
                          <!--<template slot="selection" slot-scope="data">
                                <span>{{ data.item.item }}</span>
                              </template>
                              <template slot="item" slot-scope="data">
                                <span>{{ data.item.item }}</span>
                              </template>-->
                        </v-select>
                      </v-col>
                      <v-col
                        v-if="
                          currentEvent.eventModality == 'PRESENTIAL' ||
                          currentEvent.eventModality == 'HYBRID'
                        "
                        cols="12"
                      >
                        <div
                          class="v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--rounded"
                        >
                          <div class="v-input__control d-flex">
                            <div class="v-input__slot">
                              <div class="v-input__prepend-inner">
                                <div
                                  class="v-input__icon v-input__icon--prepend-inner"
                                >
                                  <i
                                    aria-hidden="true"
                                    class="v-icon notranslate fas fa-map-marker-alt theme--light"
                                  ></i>
                                </div>
                              </div>
                              <div class="v-text-field__slot">
                                <label
                                  for="input-274"
                                  class="v-label theme--light"
                                  style="
                                    left: 0px;
                                    right: auto;
                                    position: absolute;
                                  "
                                ></label>
                                <gmap-autocomplete
                                  placeholder="Ubicación del recinto *"
                                  required
                                  style="margin-top: 15px"
                                  @place_changed="saveAddress"
                                  :value="place"
                                  :disabled="
                                    !user.permissions.Event ||
                                    !user.permissions.Event.update
                                  "
                                ></gmap-autocomplete>
                              </div>
                            </div>
                          </div>
                          <v-tooltip top max-width="350px">
                            <template v-slot:activator="{ on }">
                              <v-icon
                                color="primary"
                                dark
                                v-on="on"
                                class="ml-2 mt-4"
                                >mdi-help-circle</v-icon
                              >
                            </template>
                            <span
                              >Para llenar este campo correctamente es necesario
                              seleccionar alguna de las opciones que se sugieren
                              al momento de estar escribiendo la
                              ubicación.</span
                            >
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          currentEvent.eventModality == 'PRESENTIAL' ||
                          currentEvent.eventModality == 'HYBRID'
                        "
                        cols="6"
                        class="pb-0"
                      >
                        <v-text-field
                          v-model="
                            currentEvent.presentialEventConfiguration.city
                          "
                          label="Ciudad"
                          filled
                          rounded
                          readonly
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="
                          currentEvent.eventModality == 'PRESENTIAL' ||
                          currentEvent.eventModality == 'HYBRID'
                        "
                        cols="6"
                        class="pb-0"
                      >
                        <v-text-field
                          v-model="
                            currentEvent.presentialEventConfiguration.country
                          "
                          label="Pais"
                          filled
                          rounded
                          readonly
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="
                          currentEvent.virtualEventConfiguration &&
                          (currentEvent.eventModality == 'VIRTUAL' ||
                            currentEvent.eventModality == 'HYBRID')
                        "
                        cols="12"
                        class="pb-0"
                      >
                        <v-text-field
                          v-model="currentEvent.virtualEventConfiguration.url"
                          label="Url de Webinar"
                          filled
                          rounded
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pb-0 mt-3">
                  <p class="title font-weight-bold mb-0">
                    Configuración de ingresos
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-card class="little-border px-3 pb-2" elevation="0">
                    <v-row class="px-2">
                      <v-col cols="12" class="pb-0">
                        <!--<v-switch
                              v-model="currentEvent.comissionTransfer"
                              label="¿Deseas trasladar la comisión de la pasarela de pago al asistente?"
                            ></v-switch>-->
                        <v-switch
                          v-model="currentEvent.installments"
                          label="¿Se aceptan meses sin intereses?"
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-switch>
                        <div class="d-flex align-center justify-center">
                          <v-combobox
                            v-model="currentEvent.installmentsSelected"
                            :items="interestFeeOptions"
                            :disabled="!currentEvent.installments"
                            hide-details
                            rounded
                            filled
                            multiple
                            chips
                            label="Meses sin intereses "
                          />
                          <v-tooltip top max-width="1000px">
                            <template v-slot:activator="{ on }">
                              <v-icon
                                color="primary"
                                dark
                                v-on="on"
                                class="ml-2 mt-4"
                                >mdi-help-circle</v-icon
                              >
                            </template>
                            <!-- <span
                              >Las opciones seleccionadas aparecerán en el
                              checkout de los miembros al momento de hacer la
                              compra del evento</span
                            > -->
                            <v-simple-table class="installments-table">
                              <template v-solot:default>
                                <thead>
                                  <tr>
                                    <th class="text-center">
                                      <span class="installments-table-title">
                                        Duración del <br />
                                        plan
                                      </span>
                                    </th>
                                    <th class="text-center">
                                      <span class="installments-table-title">
                                        Importe mínimo <br />
                                        permitido
                                      </span>
                                    </th>
                                    <th class="text-center">
                                      <span class="installments-table-title">
                                        % de comisión <br />
                                        adicional
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(items, index) of installmentsList"
                                    :key="index"
                                    class="text-center"
                                  >
                                    <td>{{ items[0] + ' meses' }}</td>
                                    <td>{{ 'MXN ' + items[1].toFixed(2) }}</td>
                                    <td>{{ items[2] + '%' }}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-tooltip>
                        </div>
                        <!--<v-switch
                              label="¿El evento es gratuito?"
                            ></v-switch>-->
                      </v-col>
                      <v-col cols="12" class="pb-0">
                        <v-switch
                          v-model="currentEvent.offerBilling"
                          label="¿Ofreceras opción de facturar?"
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-switch>
                        <v-text-field
                          dense
                          rounded
                          filled
                          label="Limite de boletos por persona"
                          v-model="currentEvent.ticketsPerOrder"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              v-if="isImefOrganization(user._id, user.organization)"
            >
              <v-col cols="12" class="pb-0 mt-3">
                <p class="title font-weight-bold mb-0">
                  Datos para registro de movimientos
                </p>
              </v-col>
              <v-col cols="6">
                <v-card class="little-border px-3 py-4" elevation="0">
                  <v-select
                    v-model="categoryOnChange"
                    :items="categories"
                    label="Categoría del evento"
                    rounded
                    dense
                    filled
                    class="my-4 mb-8 self-center"
                    hide-details
                    :disabled="
                      !user.permissions.Event || !user.permissions.Event.update
                    "
                    @change="handleCategoryChange(categoryOnChange)"
                  ></v-select>
                  <div v-if="categoryOnChange == 'Evento'">
                    <div class="mb-8">
                      <UtilityCombo
                        :defVal="{
                          text: currentEvent.movementsSetup.organizator,
                          value: currentEvent.movementsSetup.organizator,
                        }"
                        :comboType="'IMEF_GROUP'"
                        :comboList="IMEF_GROUPS_LOCAL"
                        :label="'Grupo'"
                        @setUtility="updateGroup"
                        rounded
                        filled
                        :disabled="
                          !user.permissions.Event ||
                          !user.permissions.Event.update
                        "
                      />
                    </div>
                    <v-select
                      rounded
                      dense
                      filled
                      label="Tipo de evento"
                      class="mt-2"
                      v-model="currentEvent.movementsSetup.eventType"
                      :items="IMEF_SERVICES_LOCAL"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </div>
                  <div v-if="categoryOnChange == 'Consejo'">
                    <v-text-field
                      label="Año lectivo"
                      rounded
                      dense
                      filled
                      type="number"
                      v-model="currentEvent.movementsSetup.year"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                    </v-text-field>
                  </div>
                  <div v-if="categoryOnChange == 'Comité'">
                    <v-select
                      label="Tipo de comité"
                      rounded
                      dense
                      filled
                      :items="IMEF_COMMITTEE"
                      v-model="currentEvent.movementsSetup.committeeType"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    ></v-select>
                    <v-text-field
                      label="Posición"
                      rounded
                      dense
                      filled
                      v-model="currentEvent.movementsSetup.position"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                    </v-text-field>
                    <!-- <v-text-field
                      label="Año"
                      rounded
                      dense
                      filled
                      v-model="currentEvent.movementsSetup.year"
                    >
                    </v-text-field>
                    <v-text-field
                      label="Tema"
                      rounded
                      dense
                      filled
                      v-model="currentEvent.movementsSetup.topic"
                    >
                    </v-text-field> -->
                  </div>
                  <!-- <v-text-field
                        label="CEPLAN"
                        hint="Este campo es opcional"
                        persistent-hint
                        rounded
                        dense
                        filled
                        v-model="currentEvent.movementsSetup.ceplan"
                        >
                        </v-text-field> -->
                </v-card>
              </v-col>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            v-if="isImefOrganization(user._id, user.organization)"
          >
            <v-col cols="12" class="pb-0 mt-3">
              <p class="title font-weight-bold mb-0">
                Datos fiscales de evento
              </p>
            </v-col>
            <v-col cols="12">
              <v-card class="little-border mx-4 px-3 pt-6 pb-2" elevation="0">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      label="Grupo"
                      rounded
                      dense
                      filled
                      v-model="imefForm['group']"
                      :items="formatGroupsItems(IMEF_GROUPS)"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Almacén"
                      rounded
                      dense
                      filled
                      :rules="[
                        (v) =>
                          v.length <= 15 ||
                          'El código de almacén debe ser 15 caracteres máximo',
                      ]"
                      v-model="imefForm['almacen']"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                    <!-- Add rules: up to 15 characters -->
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      :items="formatServiceItems(IMEF_SERVICES)"
                      v-model="imefForm['eventCode']"
                      label="Código del evento"
                      rounded
                      dense
                      filled
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Serie"
                      rounded
                      dense
                      filled
                      v-model="imefForm['serie']"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Segmento Contable"
                      rounded
                      dense
                      filled
                      v-model="imefForm['accountingSegment']"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Lugar de expedición"
                      rounded
                      dense
                      filled
                      v-model="imefForm['zipCode']"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="12" class="pb-0 mt-3">
              <p class="title font-weight-bold mb-0">Periodo</p>
            </v-col>
            <v-col cols="12">
              <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                <v-row class="px-2">
                  <!--<v-col
                        cols="12"
                        lg="2"
                        :class="
                          $vuetify.breakpoint.mdAndDown
                            ? 'd-flex justify-center'
                            : ''
                        "
                      >
                        <v-switch label="Evento recurrente"></v-switch>
                      </v-col>-->
                  <v-col cols="12" sm="8" md="3" class="pb-0">
                    <!--<v-text-field
                          label="Fecha de inicio"
                          filled
                          rounded
                        ></v-text-field>-->
                    <v-menu
                      v-if="currentEvent.dateConfiguration"
                      v-model="dateMenu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="initialDateFormatted"
                          label="Fecha de inicio *"
                          persistent-hint
                          readonly
                          v-on="on"
                          required
                          :rules="startDateRules"
                          filled
                          rounded
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentEvent.dateConfiguration.startDate"
                        no-title
                        @input="dateMenu1 = false"
                        locale="es"
                        :min="todayDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="4" md="3" class="pb-0">
                    <v-menu
                      v-if="currentEvent.dateConfiguration"
                      ref="menu1"
                      v-model="timeMenu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="
                        currentEvent.dateConfiguration.startTime
                      "
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="currentEvent.dateConfiguration.startTime"
                          label="Hora de inicio *"
                          readonly
                          v-on="on"
                          required
                          filled
                          rounded
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="timeMenu1"
                        v-model="currentEvent.dateConfiguration.startTime"
                        @click:minute="
                          $refs.menu1.save(
                            currentEvent.dateConfiguration.startTime
                          )
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="8" md="3" class="pb-0">
                    <v-menu
                      v-if="currentEvent.dateConfiguration"
                      v-model="dateMenu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="finalDateFormatted"
                          label="Fecha de fin *"
                          persistent-hint
                          readonly
                          v-on="on"
                          required
                          filled
                          rounded
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentEvent.dateConfiguration.endDate"
                        no-title
                        @input="dateMenu2 = false"
                        :min="currentEvent.dateConfiguration.startDate"
                        locale="es"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="4" md="3" class="pb-0">
                    <v-menu
                      v-if="currentEvent.dateConfiguration"
                      ref="menu2"
                      v-model="timeMenu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="
                        currentEvent.dateConfiguration.endTime
                      "
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="currentEvent.dateConfiguration.endTime"
                          label="Hora de finalización"
                          readonly
                          v-on="on"
                          required
                          filled
                          rounded
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="timeMenu2"
                        v-model="currentEvent.dateConfiguration.endTime"
                        @click:minute="
                          $refs.menu2.save(
                            currentEvent.dateConfiguration.endTime
                          )
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                class="font-weight-bold"
                color="primary"
                rounded
                @click="$_updateEvent()"
                :disabled="
                  !user.permissions.Event || !user.permissions.Event.update
                "
              >
                Guardar cambios
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col cols="12" class="pb-0 mt-3">
              <p class="title font-weight-bold mb-0">Programar Recordatorios</p>
            </v-col>
            <v-col cols="8">
              <v-card class="little-border px-5 pt-6 pb-2" elevation="0">
                <v-row class="px-2 d-flex flex-column">
                  <v-col
                    cols="12"
                    class="pa-0"
                    v-for="(reminder, i) in getReminders"
                    :key="i"
                  >
                    <v-row no-gutters class="d-flex">
                      <v-col cols="9" class="d-flex">
                        <v-text-field
                          rounded
                          :disabled="!reminder.edit"
                          type="number"
                          filled
                          class="mr-6"
                          v-model="reminder.value"
                        ></v-text-field>
                        <v-select
                          rounded
                          filled
                          :disabled="!reminder.edit"
                          :items="reminderOptions"
                          v-model="reminder.period"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="3" class="d-flex">
                        <v-btn
                          v-if="reminder.edit == false"
                          elevation="0"
                          fab
                          small
                          @click="$_editReminder(i)"
                          class="rounded-pill ml-4 white--text"
                          :color="color.secondaryVariant"
                        >
                          <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="reminder.edit == true"
                          elevation="0"
                          fab
                          small
                          @click="
                            $_postReminder({ reminder: reminder, index: i })
                          "
                          class="rounded-pill ml-4 white--text"
                          color="green"
                        >
                          <v-icon> mdi-content-save-outline </v-icon>
                        </v-btn>
                        <v-btn
                          elevation="0"
                          fab
                          @click="
                            reminder.edit
                              ? $_closeReminderEdition(i)
                              : $_deleteReminder(i)
                          "
                          small
                          class="rounded-pill ml-4 white--text"
                          color="red"
                        >
                          <v-icon>
                            {{
                              reminder.edit ? 'mdi-window-close' : 'mdi-delete'
                            }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center" v-if="false">
                    <span>Agregar Recordatorio</span>
                    <v-btn
                      elevation="0"
                      fab
                      :disabled="!(getReminders.length < 4)"
                      @click="$_createReminder"
                      small
                      class="rounded-pill ml-4"
                      :color="color.secondaryVariant"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="px-3">
            <v-col cols="12" class="pb-0 mt-3 d-flex align-center">
              <p class="title font-weight-bold mb-1 mt-4">
                Preguntas frecuentes
              </p>
              <v-tooltip top max-width="350px">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on" class="ml-2 mt-4"
                    >mdi-help-circle</v-icon
                  >
                </template>
                <span
                  >En este apartado podrás agregar las preguntas que comúnmente
                  te hacen tus asistentes.<br />Al momento de que tus asistentes
                  ingresen a tu micrositio podrán ver estas preguntas.</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12">
              <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                <v-row class="px-2 pb-2">
                  <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    class="pb-3"
                    v-for="(faq, index) in eventFaqs"
                    :key="index"
                  >
                    <v-form
                      :ref="`faqForm${index}`"
                      v-model="validFaqsForm[index]"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            v-model="faq.question"
                            label="Pregunta"
                            filled
                            rounded
                            :disabled="
                              editingFaqId != faq.id ||
                              !user.permissions.Event ||
                              !user.permissions.Event.update
                            "
                            :rules="questionRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            v-model="faq.answer"
                            filled
                            rounded
                            name="input-7-4"
                            label="Respuesta"
                            :disabled="
                              editingFaqId != faq.id ||
                              !user.permissions.Event ||
                              !user.permissions.Event.update
                            "
                            :rules="answerRules"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pt-0">
                          <!--<v-btn class="mx-2" fab small color="primary" :disabled="!validFaqsForm[index]">
                                <v-icon> mdi-plus </v-icon>
                              </v-btn>-->
                          <!--mdi-content-save-->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="#FF7043"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  !user.permissions.Event ||
                                  !user.permissions.Event.update
                                "
                                @click="openDeleteFaqDialog(faq)"
                              >
                                <v-icon dark> mdi-trash-can-outline </v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar pregunta frecuente</span>
                          </v-tooltip>
                          <v-tooltip v-if="editingFaqId == faq.id" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  !validFaqsForm[index] ||
                                  faq.answer == '' ||
                                  faq.question == '' ||
                                  !user.permissions.Event ||
                                  !user.permissions.Event.update
                                "
                                @click="$_updateEventFaq(faq)"
                              >
                                <v-icon> mdi-content-save </v-icon>
                              </v-btn>
                            </template>
                            <span>Guardar cambios</span>
                          </v-tooltip>
                          <v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="secondary"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  !user.permissions.Event ||
                                  !user.permissions.Event.update
                                "
                                @click="enableFaqEdition(faq.id)"
                              >
                                <v-icon color="black"> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Editar pregunta frecuente</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" class="pb-3">
                    <v-form
                      ref="newFaqForm"
                      v-model="validNewFaqForm"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            v-model="newFaq.question"
                            label="Pregunta"
                            filled
                            rounded
                            :rules="questionRules"
                            :disabled="
                              !user.permissions.Event ||
                              !user.permissions.Event.update
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            v-model="newFaq.answer"
                            filled
                            rounded
                            name="input-7-4"
                            label="Respuesta"
                            :rules="answerRules"
                            :disabled="
                              !user.permissions.Event ||
                              !user.permissions.Event.update
                            "
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center pt-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                fab
                                small
                                color="primary"
                                :disabled="
                                  !validNewFaqForm ||
                                  newFaq.answer == '' ||
                                  newFaq.question == '' ||
                                  !user.permissions.Event ||
                                  !user.permissions.Event.update
                                "
                                v-bind="attrs"
                                v-on="on"
                                @click="$_createEventFaq()"
                              >
                                <v-icon> mdi-plus </v-icon>
                              </v-btn>
                            </template>
                            <span>Crear pregunta frecuente</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <!--<v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            label="Pregunta"
                            filled
                            rounded
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            filled
                            rounded
                            name="input-7-4"
                            label="Respuesta"
                            value=""
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-btn class="mx-2" fab dark small color="#FF7043">
                            <v-icon dark> mdi-trash-can-outline </v-icon>
                          </v-btn>
                          <v-btn class="mx-2" fab dark small color="primary">
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-col cols="12" class="pb-0">
                          <v-text-field
                            label="Pregunta"
                            filled
                            rounded
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-textarea
                            filled
                            rounded
                            name="input-7-4"
                            label="Respuesta"
                            value=""
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-btn class="mx-2" fab dark small color="#FF7043">
                            <v-icon dark> mdi-trash-can-outline </v-icon>
                          </v-btn>
                          <v-btn class="mx-2" fab dark small color="primary">
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-col>-->
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!--
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab href="#eventConfiguration"> Evento </v-tab>
            <v-tab href="#documents"> Documentos </v-tab>
            <v-tab href="#billingData"> Emisor fiscal </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="eventConfiguration">
              
            </v-tab-item>
            <v-tab-item value="documents">
              <v-row class="px-3">
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0 mt-7">
                      <p class="title font-weight-bold mb-0">Ticket ID</p>
                    </v-col>
                    <v-col cols="12">
                      <v-card
                        class="little-border px-3 pt-6 pb-2"
                        elevation="0"
                      >
                        <v-row>
                          <v-col cols="12" md="4" class="pb-0">
                            <v-img
                              src="https://borealtech.com/wp-content/uploads/2018/10/codigo-qr-1024x1024.jpg"
                            ></v-img>
                            <p class="caption text-center">
                              Marca personal: Un reto para todo influencer
                            </p>
                          </v-col>
                          <v-col cols="12" md="8" class="pb-0">
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  class="mt-0"
                                  label="Color primario"
                                  filled
                                  rounded
                                  readonly
                                  @click="primaryColorDialog = true"
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      :color="primaryColor"
                                      large
                                      @click="primaryColorDialog = true"
                                      >fas fa-square</v-icon
                                    >
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  label="Color secundario"
                                  filled
                                  rounded
                                  readonly
                                  @click="secondaryColorDialog = true"
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      :color="secondaryColor"
                                      large
                                      @click="secondaryColorDialog = true"
                                      >fas fa-square</v-icon
                                    >
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                <v-textarea
                                  filled
                                  rounded
                                  name="input-7-4"
                                  label="Ingresa las instrucciones del Acceso ID"
                                  value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-end align-center pt-0"
                          >
                            <span class="mx-3"
                              >Activar adjunto en formato PDF</span
                            >
                            <v-switch></v-switch>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0 mt-7">
                      <p class="title font-weight-bold mb-0">Constancia</p>
                    </v-col>
                    <v-col cols="12">
                      <v-card
                        class="little-border px-3 pt-6 pb-2"
                        elevation="0"
                      >
                        <v-row class="px-10">
                          <v-col cols="12" class="d-flex justify-end">
                            <v-btn
                              class="black--text"
                              color="accent"
                              large
                              rounded
                            >
                              <v-icon> mdi-plus </v-icon>
                              <span class="font-weight-bold"
                                >Cargar plantilla</span
                              >
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <p class="text-justify">
                              Carga tu plantilla en formato de imagen PNG con un
                              tamaño de 1584x1224 en formato horizontal.
                              Recuerda dejar espacio para ubicar el nombre de tu
                              asistente y evento.
                            </p>
                          </v-col>
                          <v-col cols="12" class="pb-0">
                            <p class="font-weight-bold">Previsualización</p>
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <v-img
                              src="@/assets/images/dashboard/certificatePlaceholder.jpg"
                              class="rounded-lg"
                            ></v-img>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="billingData">
              <v-row class="px-3">
                <v-col cols="12" class="pb-0 mt-7">
                  <p class="title font-weight-bold mb-0">
                    Datos de identificación fiscal
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                    <v-row class="px-2 my-5">
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          label="País"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          label="RFC o ID Fiscal"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          label="Nombre comercial"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="pb-0">
                        <v-text-field
                          label="Razón social"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="pb-0">
                        <v-text-field
                          label="Régimen fiscal"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          label="Teléfono"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="pb-0">
                        <v-text-field
                          label="Sitio web"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="px-3 d-flex">
                <v-col cols="12" md="8">
                  <v-row>
                    <v-col cols="12" class="pb-0 mt-7">
                      <p class="title font-weight-bold mb-0">
                        Domicilio fiscal
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-card
                        class="little-border px-3 pt-6 pb-2"
                        elevation="0"
                      >
                        <v-row class="px-2">
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Calle"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Núm. exterior"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Núm. interior"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Colonia"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Código postal"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Ciudad"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Municipio"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" class="pb-0">
                            <v-text-field
                              label="Estado"
                              filled
                              rounded
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" class="pb-0 mt-7">
                      <p class="title font-weight-bold mb-0">
                        Logotipo para factura o recibo
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-card
                        class="little-border px-3 pt-6 pb-2"
                        elevation="0"
                      >
                        <v-row class="px-2">
                          <v-col cols="12" class="d-flex justify-center">
                            <v-btn color="accent" fab large>
                              <i class="fas fa-plus fa-2x black--text"></i>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="12" class="pb-0 mt-7">
                  <p class="title font-weight-bold mb-0">
                    Certificados de sello digital (CSD)
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-card class="little-border px-3 pt-6 pb-2" elevation="0">
                    <v-row class="px-2">
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6" class="d-flex justify-center">
                            <v-btn
                              class="custom-transform-class text-none mx-3 mt-3"
                              color="primary"
                              rounded
                            >
                              Certificado .cer
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="6" class="d-flex justify-center">
                            <v-btn
                              class="custom-transform-class text-none mx-3 mt-3"
                              color="primary"
                              rounded
                            >
                              Llave privada .key
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="Contraseña"
                              type="password"
                              filled
                              rounded
                              autocomplete="new-password"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="4"
                        md="3"
                        xl="2"
                        offset="6"
                        offset-sm="8"
                        offset-md="3"
                        offset-xl="4"
                      >
                        <v-row>
                          <v-col cols="6">
                            <v-img
                              width="100"
                              src="@/assets/images/dashboard/aws.svg"
                            ></v-img>
                          </v-col>
                          <v-col cols="6">
                            <v-img
                              width="120"
                              src="@/assets/images/dashboard/pci.svg"
                            ></v-img>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>-->
        </v-col>
      </v-row>
      <v-dialog v-model="primaryColorDialog" max-width="400">
        <v-card>
          <p class="headline font-weight-medium px-7 pt-5">
            Selecciona el color primario
          </p>
          <v-card-text class="d-flex justify-center pa-2">
            <v-color-picker
              v-model="primaryColor"
              class="ma-0 ma-md-2"
              mode="hexa"
              hide-mode-switch
              show-swatches
              swatches-max-height="300px"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
            ></v-color-picker>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pb-5 pr-5">
            <v-btn color="grey lighten-2" @click="primaryColorDialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="accent"
              @click="primaryColorDialog = false"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="secondaryColorDialog" max-width="400">
        <v-card>
          <p class="headline font-weight-medium px-7 pt-5">
            Selecciona el color secundario
          </p>
          <div class="d-flex justify-center pa-2">
            <v-color-picker
              v-model="secondaryColor"
              class="ma-0 ma-md-2"
              mode="hexa"
              hide-mode-switch
              show-swatches
              swatches-max-height="300px"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
            ></v-color-picker>
          </div>
          <v-card-actions class="d-flex justify-end pb-5 pr-5">
            <v-btn color="grey lighten-2" @click="secondaryColorDialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              color="accent"
              @click="secondaryColorDialog = false"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- INICIO Dialog para confirmar la eliminación de una pregunta frecuente -->
      <v-dialog v-model="deleteFaqDialog" width="600">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="220"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-2">
                <p class="text-center font-weight-bold headline mb-0">
                  Eliminar pregunta frecuente
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="title text-center mb-0">
                  ¿Está seguro que quiere eliminar el registro seleccionado?
                </p>
                <p class="subtitle-1 text-center">
                  La pregunta no podrá ser recuperada a menos que se cree otra
                  igual.
                </p>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="font-weight-bold black--text mx-2"
                color="grey lighten-3"
                rounded
                elevation="0"
                @click="deleteFaqDialog = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="font-weight-bold black--text mx-2"
                color="accent"
                rounded
                elevation="0"
                :disabled="
                  !user.permissions.Event || !user.permissions.Event.update
                "
                @click="$_deleteEventFaq()"
              >
                Aceptar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- FIN Dialog para confirmar la eliminación de una pregunta frecuente -->
    </v-card>
  </v-container>
</template>
<script>
import { validations } from '@/share/validations.js';
import { mapState, mapMutations, mapActions } from 'vuex';
import { colors } from '@/constants/colors.js';
import UtilityCombo from '../../../components/shared/UtilityCombo.vue';
import {
  formatGroupsItems,
  formatServiceItems,
  getAccountingSegments,
  isImefOrganization,
  IMEF_GROUPS,
  IMEF_SERVICES,
} from '../../../share/imef';
import { installmentsList } from '@/utils/installments.js';
import moment from 'moment-timezone';

export default {
  components: {
    UtilityCombo,
  },
  computed: {
    ...mapState('event', ['eventSelected', 'eventFaqs']),
    ...mapState('user', ['user']),
    /**
     * Función para tomar la fecha actual y ponerla en los v-date-picker.
     */
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    /**
     * Función para dar formato de la fecha inicial del evento.
     */
    initialDateFormatted() {
      if (this.isNewEvent)
        return this.formatDate(this.currentEvent.dateConfiguration.startDate);
      else {
        return this.formatDate(
          this.currentEvent.dateConfiguration.startDate.substring(0, 10)
        );
      }
    },
    /**
     * Función para dar formate de la fecha final del evento.
     */
    finalDateFormatted() {
      if (this.isNewEvent)
        return this.formatDate(this.currentEvent.dateConfiguration.endDate);
      else {
        return this.formatDate(
          this.currentEvent.dateConfiguration.endDate.substring(0, 10)
        );
      }
    },
    /**
     * Función para retornar arreglo de recordarorios
     */
    getReminders: {
      get() {
        return this.eventReminders;
      },
      set({ reminder, typeOrder, index }) {
        switch (typeOrder) {
          case 'ADD':
            this.eventReminders.push(reminder);
            break;
          case 'EDIT':
            this.eventReminders[index].edit = true;
            break;
          case 'MODIFY':
            this.eventReminders.splice(index, 1, reminder);
            break;
          case 'CLOSE':
            this.eventReminders[index].edit = false;
            break;
          case 'DELETE':
            this.eventReminders.splice(index, 1);
            break;
        }
        this.eventSelected.reminders = this.eventReminders;
      },
    },
  },
  data() {
    return {
      IMEF_GROUPS,
      IMEF_SERVICES,
      IMEF_COMMITTEE: [],
      installmentsList,
      imefForm: {
        group: '',
        almacen: '',
        eventCode: '',
        serie: '',
        accountingSegment: '',
        zipCode: '',
      },
      startDateRules: validations.requiredFValidation(
        'fecha de inicio del evento'
      ),
      IMEF_GROUPS_LOCAL: [],
      IMEF_SERVICES_LOCAL: [],
      groupSearch: null,
      groupEditing: null,
      prevGroupEditing: '',
      groupEditingIndex: -1,
      categoryOnChange: '',
      tab: 'eventConfiguration',
      color: colors,
      dateMenu1: false,
      dateMenu2: false,
      timeMenu1: false,
      timeMenu2: false,
      interestFeeSelected: [],
      interestFeeOptions: [
        {
          text: '3 meses',
          value: 3,
        },
        {
          text: '6 meses',
          value: 6,
        },
        {
          text: '9 meses',
          value: 9,
        },
        {
          text: '12 meses',
          value: 12,
        },
        {
          text: '18 meses',
          value: 18,
        },
        {
          text: '24 meses',
          value: 24,
        },
      ],
      modalityCategories: [
        {
          text: 'Presencial',
          value: 'PRESENTIAL',
        },
        {
          text: 'En línea',
          value: 'VIRTUAL',
        },
        {
          text: 'Híbrido',
          value: 'HYBRID',
        },
      ],
      reminderOptions: [
        {
          text: 'Minutos',
          value: 'MINUTES',
        },
        {
          text: 'Horas',
          value: 'HOURS',
        },
        {
          text: 'Días',
          value: 'DAYS',
        },
        {
          text: 'Semanas',
          value: 'WEEKS',
        },
      ],
      eventReminders: [],
      /*
      typeCategories: [
        {
          text: "Concierto",
          value: "Concert",
        },
        {
          text: "Conferencia",
          value: "Conference",
        },
        {
          text: "Congreso",
          value: "Congres",
        },
        {
          text: "Evento empresarial",
          value: "Empresarial Event",
        },
        {
          text: "Feria",
          value: "Fair",
        },
        {
          text: "Foro",
          value: "Forum",
        },
        {
          text: "Obra de Tetro",
          value: "Play",
        },
        {
          text: "Simposio",
          value: "Symposium",
        },
        {
          text: "Taller",
          value: "Workshop",
        },
        {
          text: "Otro",
          value: "Other",
        },
      ],
      personalizationCategories: [
        {
          item: "Académico",
          value: "Academic",
        },
        {
          item: "Arte",
          value: "Art",
        },
        {
          item: "Bienestar",
          value: "Welfare",
        },
        {
          item: "Capacitación",
          value: "Training",
        },
        {
          item: "Obra Benefica",
          value: "Charity",
        },
        {
          item: "Cine",
          value: "Cinema",
        },
        {
          item: "Gastronomía",
          value: "Gastronomy",
        },
        {
          item: "Cultura",
          value: "Culture",
        },
        {
          item: "Deporte",
          value: "Sport",
        },
        {
          item: "Emprendimiento",
          value: "Entreprenueship",
        },
        {
          item: "Empresarial",
          value: "Empresarial",
        },
        {
          item: "Politica",
          value: "Politic",
        },
        {
          item: "Publicidad",
          value: "Advertising",
        },
        {
          item: "Religión",
          value: "Religion",
        },
        {
          item: "Social",
          value: "Social",
        },
        {
          item: "Otro",
          value: "Other",
        },
      ],
      */
      eventType: [
        'Curso / Taller ',
        'Foro / Congreso / Expo',
        'Conferencia',
        'Diplomado / Posgrado',
        'Otro',
      ],
      categories: ['Evento', 'Consejo', 'Comité'],
      eventCategory: [
        'Académico',
        'Arte',
        'Bienestar',
        'Capacitación',
        'Obra benéfica',
        'Cine',
        'Gastronomía',
        'Concierto',
        'Cultura',
        'Deporte',
        'Emprendimiento',
        'Empresarial',
        'Política',
        'Publicidad',
        'Religión',
        'Social',
        'Otro',
      ],
      eventAmenities: [
        'Estacionamiento',
        'Coffee Break',
        'Alimentos',
        'Aire Acondicionado',
        'Constancia',
        'Cóctel',
        'Valet Parking',
        'Zona Comercial',
        'Hospedaje',
      ],
      /*eventAmenities: [
        {
          text: "Estacionamiento",
          value: "Parking",
        },
        {
          text: "Coffee Break",
          value: "Coffee Break",
        },
        {
          text: "Alimentos",
          value: "Food",
        },
        {
          text: "Aire Acondicionado",
          value: "Air Conditioner",
        },
        {
          text: "Constancia",
          value: "Constancy",
        },
        {
          text: "Cóctel",
          value: "Coctail",
        },
        {
          text: "Valet Parking",
          value: "Valet Parking",
        },
        {
          text: "Zona Comercial",
          value: "Comertial Zone",
        },
      ],*/
      paymentModality: ['De pago', 'Gratis', 'Mixta'],
      previousTags: [],
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      // Documentos
      primaryColorDialog: false,
      secondaryColorDialog: false,
      primaryColor: '#000000',
      secondaryColor: '#000000',
      // Copia del evento
      currentEvent: {
        _id: '',
        amenities: [],
        tags: [],
        published: false,
        ticketsPerOrder: 0,
        banner: '',
        name: '',
        eventCode: '',
        description: '',
        youtubeVideo: '',
        emailContact: '',
        micrositeConfiguration: {
          link: '',
          primaryColor: '',
          secondaryColor: '',
          buttonColor: '',
          textButtonColor: 'black',
        },
        presentialEventConfiguration: {
          name: '',
          city: '',
          country: '',
          formattedAddress: '',
          latitude: null,
          longitude: null,
        },
        socialMedia: {
          facebook: '',
          instagram: '',
          linkedin: '',
          twitter: '',
          youtubeChanel: '',
        },
        movementsSetup: {
          category: '',
          organizator: '',
          eventType: '',
          position: '',
          committeeType: '',
          position: '',
          eventCode: '',
        },
        dateConfiguration: {
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: ''
        }
      },
      place: null,
      newFaq: {
        faqId: '',
        question: '',
        answer: '',
        event: '',
      },
      newFaqObj: {
        faqId: '',
        question: '',
        answer: '',
        event: '',
      },
      validNewFaqForm: false,
      validFaqsForm: [],
      editingFaqId: null,
      currentDeletedFaq: {
        id: '',
        event: '',
      },
      localTimeZone: null,
      deleteFaqDialog: false,
      // Rules
      questionRules: validations.questionFieldValidation({
        name: 'campo',
        maxLength: 250,
      }),
      answerRules: validations.answerFieldValidation({ name: 'campo' }),
    };
  },
  watch: {
    'imefForm.group': function () {
      this.imefForm.zipCode = this.imefForm?.group?.zipCode || '';
      this.imefForm.serie = this.imefForm?.group?.Serie || '';
      this.imefForm.accountingSegment = this.imefForm?.group?.segment || '';
    },
    'currentEvent.installments'(enable) {
      if (!enable) {
        this.currentEvent.installmentsSelected = [];
      }
    },
  },
  methods: {
    ...mapActions('event', [
      'fetchEventById',
      'postEventReminder',
      'deleteEventReminder',
      'updateEvent',
      'fetchEventFaqs',
      'createEventFaq',
      'updateEventFaq',
      'deleteEventFaq',
    ]),
    ...mapMutations('loader', ['loading', 'loaded']),
    ...mapMutations('notification', ['show']),
    formatGroupsItems,
    formatServiceItems,
    getAccountingSegments,
    isImefOrganization,
    async getPreviousTags() {
      const response = await this.axios({
        method: 'GET',
        url: 'events/tags',
      });

      console.log(response);

      if (response.status == 200) {
        this.previousTags = response.data.data.tags;
      } else {
        this.show({
          text: 'Hubo un problema al obtener los tags',
          color: 'error',
        });
      }
    },

    formatDateUTC({ endDate = null, endTime = null, timeZone = null }) {
      if (!endDate || !endTime) return;

      // Crear nueva fecha con el registro en la db en formato UTC
      return moment.tz(
        (new Date(endDate).toISOString()).slice(0,11) + endTime,
        timeZone
      ).clone().tz('UTC').format()
    },

    async $_fetchEvent() {
      await this.fetchEventById(this.$route.params.eventId);
      if (
        this.eventSelected.presentialEventConfiguration &&
        this.eventSelected.presentialEventConfiguration.formattedAddress
      ) {
        this.place =
          this.eventSelected.presentialEventConfiguration.formattedAddress;
      }

      this.imefForm = this.eventSelected?.imefFiscalData || {};
      this.eventReminders = this.eventSelected.reminders.map((reminder) => {
        return { ...reminder, edit: false };
      });
      await this.fetchEventFaqs(this.$route.params.eventId);
      this.currentEvent = this.eventSelected;

      // Tomar Fecha en formato utc y convertirlo a hora local
      const time = moment.tz(
        this.formatDateUTC(this.eventSelected.dateConfiguration),
        this.localTimeZone
      )

      this.currentEvent.dateConfiguration.endTime = `${time.format('HH:mm')}`
      if (this.currentEvent?.installmentsSelected?.length != 0) {
        this.currentEvent.installmentsSelected = [
          ...this.currentEvent.installmentsSelected,
        ].map(({ count }) => ({ text: `${count} meses`, value: count }));
      }
      if (!this.currentEvent.movementsSetup) {
        this.currentEvent.movementsSetup = {
          category: '',
          organizator: '',
          eventType: '',
        };
      } else {
        this.categoryOnChange = this.currentEvent.movementsSetup.category;
      }
      ////////
    },
    $_createReminder() {
      console.log(this.getReminders.length);
      if (this.getReminders.length < 4) {
        this.getReminders = {
          reminder: {
            value: 0,
            period: 'MINUTES',
            edit: true,
          },
          typeOrder: 'ADD',
        };
      } else {
        this.show({
          text: 'Error: has excedido la cantidad de recordatorios para agendar',
          color: 'error',
        });
      }
    },
    async $_deleteReminder(index) {
      try {
        let response = await this.deleteEventReminder({
          eventId: this.$route.params.eventId,
          reminderId: this.getReminders[index]._id,
        });
        if (response.status == 200) {
          this.getReminders = {
            index: index,
            typeOrder: 'DELETE',
          };
          this.show({
            text: response.message,
            color: 'primary',
          });
        }
      } catch (error) {
        this.show({
          text: 'Error: parece que el recordatorio no se ha podido registrar, intentalo nuevamente.',
          color: 'error',
        });
        console.log(error);
      }
    },
    $_editReminder(index) {
      this.getReminders = {
        index: index,
        typeOrder: 'EDIT',
      };
    },
    $_closeReminderEdition(index) {
      this.getReminders = {
        index: index,
        typeOrder: 'CLOSE',
      };
    },
    async $_postReminder({ reminder, index }) {
      try {
        console.log(index);
        console.log(this.$route.params.eventId);
        let response = await this.postEventReminder({
          eventId: this.$route.params.eventId,
          reminder: reminder,
        });
        if (response.status == 200) {
          console.log(response);
          const { _id, value, period } = response.reminder;
          this.getReminders = {
            index: index,
            reminder: new Object({ value, _id, period, edit: false }),
            typeOrder: 'MODIFY',
          };
          console.log(this.getReminders);
          this.show({
            text: response.message,
            color: 'primary',
          });
        }
      } catch (error) {
        this.show({
          text: 'Error: parece que el recordatorio no se ha podido registrar, intentalo nuevamente.',
          color: 'error',
        });
        console.log(error);
      }
    },
    async $_updateEvent() {
      this.loading();
      if (this.isImefOrganization(this.user._id, this.user.organization)) {
        this.currentEvent['imefFiscalData'] = this.imefForm;
      }

      this.currentEvent.dateConfiguration.timeZone = this.localTimeZone;

      try {
        let response = await this.updateEvent(this.currentEvent);
        if (response.status) {
          this.show({
            text: response.message,
            color: 'primary',
          });
        }
      } catch (error) {
        // testear error catch
        this.show({
          text: error.response.message,
          color: 'error',
        });
      } finally {
        await this.$_fetchEvent();
        this.loaded();
      }
    },
    async $_createEventFaq() {
      this.loading();
      this.newFaq.eventId = this.$route.params.eventId;
      let response = await this.createEventFaq(this.newFaq);
      await this.fetchEventFaqs(this.$route.params.eventId);
      this.newFaq = JSON.parse(JSON.stringify(this.newFaqObj));
      this.show({
        text: response.message,
        color: response.status ? 'primary' : 'error',
      });
      this.loaded();
    },
    async $_updateEventFaq(faqData) {
      this.loading();
      let response = await this.updateEventFaq(faqData);
      await this.fetchEventFaqs(this.$route.params.eventId);
      this.newFaq = JSON.parse(JSON.stringify(this.newFaqObj));
      this.show({
        text: response.message,
        color: response.status ? 'primary' : 'error',
      });
      this.loaded();
    },
    async $_deleteEventFaq() {
      this.loading();
      this.deleteFaqDialog = false;
      let response = await this.deleteEventFaq(this.currentDeletedFaq);
      await this.fetchEventFaqs(this.$route.params.eventId);
      this.show({
        text: response.message,
        color: response.status ? 'primary' : 'error',
      });
      this.loaded();
    },
    enableFaqEdition(faqId) {
      this.editingFaqId = faqId;
    },
    openDeleteFaqDialog(faqData) {
      this.currentDeletedFaq.id = faqData.id;
      this.currentDeletedFaq.event = faqData.event;
      this.deleteFaqDialog = true;
    },
    /**
     * Función para dar formato de fecha YYYY-MM-DD.
     * @param {String} date Fecha inicial o final del evento.
     */
    formatDate(date) {
      if (!date) return null;
      //
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    /**
     * Función para guardar dirección del evento una vez que ha sido ingresada en el campo de dirección de evento.
     */
    async saveAddress(place) {
      let {
        geometry: {
          location: { lat },
        },
        geometry: {
          location: { lng },
        },
        formatted_address,
        name,
      } = place;
      let flag = isNaN(place.address_components.slice(-1)[0].short_name)
        ? true
        : false;
      let components = [];
      if (flag) components = [...place.address_components].slice(-3);
      else components = [...place.address_components].slice(-4);

      this.currentEvent.presentialEventConfiguration = {
        name,
        city: components[0].long_name,
        country: components[2].long_name,
        formattedAddress: formatted_address,
        latitude: lat(),
        longitude: lng(),
        //state: components[1].short_name,
      };
      this.place = formatted_address;
    },

    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : '');

      const text = hasValue(itemText).toString().toLowerCase();
      const query = hasValue(queryText).toString().toLowerCase();

      return text.includes(query);
    },

    async $_fetchImefUtilities() {
      try {
        let response = await this.axios({
          method: 'GET',
          url: '/utilities/fetch-utilities',
          params: {
            utilityTypes: ['IMEF_GROUP', 'IMEF_EVENT', 'IMEF_COMMITTEE'],
          },
        });

        if (response.status == 200) {
          const {
            IMEF_GROUP = [],
            IMEF_EVENT = [],
            IMEF_COMMITTEE = [],
          } = response.data.utilityLoad;
          this.IMEF_GROUPS_LOCAL = IMEF_GROUP;
          this.IMEF_SERVICES_LOCAL = IMEF_EVENT;
          this.IMEF_COMMITTEE = IMEF_COMMITTEE;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async handleCategoryChange(newCategory) {
      this.currentEvent.movementsSetup = {
        organizator: '',
      };
      this.currentEvent.movementsSetup.category = newCategory;
    },

    async updateGroup({ newValue = '', updateList = false }) {
      this.currentEvent.movementsSetup.organizator = newValue;
      if (updateList) {
        await this.$_fetchImefUtilities();
      }
    },
  },
  async mounted() {
    this.loading();
    this.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await this.$_fetchEvent();
    if (this.isImefOrganization(this.user._id, this.user.organization)) {
      await this.$_fetchImefUtilities();
    }
    this.loaded();
  },
  async created() {
    await this.getPreviousTags();
  },
};
</script>
<style scoped>
.little-border {
  border: 2px solid #f0f0f0;
  border-radius: 15px;
}

.installments-table {
  background-color: transparent;
  color: white;
}

.installments-table-title {
  color: white;
}
</style>
